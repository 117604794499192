// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

var protokolleContainer = jQuery('[data-protokolle-container]');
var protokolleContainerVS = jQuery('[data-protokolle-container-vs]');
// var protokolleContainerA = jQuery('[data-protokolle-container-a]');
var loadMoreButton =jQuery(protokolleContainer).find('[data-load-more-button ]');
var loadMoreButtonVS =jQuery(protokolleContainerVS).find('[data-load-more-button-vs ]');
var loadMoreButtonA =jQuery('[data-load-more-button-a ]');


/*  
** FILTER PROTOKOLLE
*/
var today = new Date();


const filterButton = jQuery(document).find('[data-filter-button]');

var filterFormYear = jQuery('[data-select-year]');
var filterFormMonth = jQuery('[data-select-month]');
var filterFormCategory = jQuery('[data-select-category]');

var selectedMonth = 'unset'
var selectedYear = 'unset'
var selectedCategory = 'unset';

filterFormYear.on('change', function() {
  selectedYear = jQuery(this).children('option:selected').val()  
})

filterFormMonth.on('change', function() {
  selectedMonth = jQuery(this).children('option:selected').val()
})

filterFormCategory.on('change', function() {
  selectedCategory = jQuery(this).children('option:selected').val()
})

filterButton.on('click', function() {

      var origin  = window.location.origin;
      var url = origin  + '/wp-admin/admin-ajax.php';
      var offset = 0;
      var currPage = 0;

      console.log('clicked filter');
      jQuery.ajax({
        type: 'POST',
        url: url,
        dataType: 'html',
        data: {
          offset: offset,
          currPage: currPage,
          action: 'filter_function',
          category: selectedCategory,
          year: selectedYear,
          month: selectedMonth,
          // date,
        },

        success: function (data) {
          offset = offset + 9;
          currPage = currPage + 1;
          jQuery('[data-ajax]').html('');
          jQuery('[data-ajax]').append(data);
            
        },
        error: function (errorThrown) {
            console.log('error:', errorThrown);
            console.log('error', $(this).data);

        },
      });
})


/*  
** FILTER AKTUELLES 
*/
const filterButtonA = jQuery(document).find('[data-filter-button-a]');

var filterFormYearA = jQuery('[data-select-year-a]');
var filterFormMonthA = jQuery('[data-select-month-a]');
var filterFormCategoryA = jQuery('[data-select-category-a]');
var urlA = origin  + '/wp-admin/admin-ajax.php';

var todayA = new Date();
var currentMonthA = String(todayA.getMonth() + 1).padStart(2, '0'); //January is 0!
var currentYearA = todayA.getFullYear();


var selectedMonthA = currentMonthA;
var selectedYearA = currentYearA;
var selectedCategoryA = 'unset';

filterFormYearA.on('change', function() {
  selectedYearA = jQuery(this).children('option:selected').val()  
})

filterFormMonthA.on('change', function() {
  selectedMonthA = jQuery(this).children('option:selected').val()
})

filterFormCategoryA.on('change', function() {
  selectedCategoryA = jQuery(this).children('option:selected').val()
})

filterButtonA.on('click', function() {

      console.log('clicked filter filterButtonA');
      jQuery.ajax({
        type: 'POST',
        url: urlA,
        dataType: 'html',
        data: {
          // offset: offset,
          // currPage: currPage,
          action: 'filter_a_function',
          category: selectedCategoryA,
          year: selectedYearA,
          month: selectedMonthA,
          // date,
        },

        success: function (data) {
          // offset = offsetA + 10;
          // currPage = currPage + 1;
          jQuery('[data-ajax-a]').html('');
          jQuery('[data-ajax-a]').append(data);
            
        },
        error: function (errorThrown) {
            console.log('error:', errorThrown);
            console.log('error', $(this).data);

        },
      });
})

/*  
** LOAD MORE AKTUELLES
*/

// var offsetLMA = 9;
// var currPageLMA = 1;

function loadMoreA() {

// console.log('load more button a');
// var dataAjaxA = jQuery(this).parent().parent().find('[data-ajax-a]');
// var dataAttributes = jQuery(dataAjaxA).find('[data-year]');

// var filterFormYearLMA = dataAttributes.data('year');
// var filterFormMonthLMA = dataAttributes.data('month');
// var filterFormCategoryLMA = dataAttributes.data('category');

var originLMA  = window.location.origin;
var urlLMA = originLMA  + '/wp-admin/admin-ajax.php';


  jQuery.ajax({
    type: 'POST',
    url: urlLMA,
    dataType: 'html',
    data: {
      // offsetLMA: offsetLMA,
      // offset: offset,
      // currPageLMA: currPageLMA,
      action: 'filter_a_lm_function',
      // category: filterFormCategoryLMA,
      // year: filterFormYearLMA,
      // month: filterFormMonthLMA,
      // date,
    },

    

    success: function (data) {
      // offsetLMA += 9 ;
      // currPageLMA += 1 ;
      // console.log('offsetLMA', offsetLMA);
      // console.log('currPageLMA', currPageLMA);
      console.log('data', data);
      jQuery('[data-ajax-a] div').html('');
      jQuery('[data-ajax-a] div').append(data);
      loadMoreButtonA.hide();

    },
    error: function (errorThrown) {
        console.log('error:', errorThrown);
        

    },
  });
}

loadMoreButtonA.on('click', function() {  
  console.log('clicked load more a');
  var offsetLMA = 0;
      loadMoreA(offsetLMA);
})


/*  
** FILTER VORSTANDSSITZUNG
*/
const filterButtonVS = jQuery(document).find('[data-filter-button-vs]');
// const protokolleContainer = jQuery(document).find('[ data-protokolle-container]');

var todayVS = new Date();
var currentMonthVS = String(todayVS.getMonth() + 1).padStart(2, '0'); //January is 0!
var currentYearVS = today.getFullYear();


var filterFormYearVS = jQuery('[data-select-year-vs]');
var filterFormMonthVS = jQuery('[data-select-month-vs]');
var filterFormCategoryVS = jQuery('[data-select-category-vs]');

var selectedMonthVS = currentMonthVS;
var selectedYearVS = currentYearVS;
var selectedCategoryVS = '';

filterFormYearVS.on('change', function() {
  selectedYearVS = jQuery(this).children('option:selected').val()  
})

filterFormMonthVS.on('change', function() {
  selectedMonthVS = jQuery(this).children('option:selected').val()
})

filterFormCategoryVS.on('change', function() {
  selectedCategoryVS = jQuery(this).children('option:selected').val()
})

filterButtonVS.on('click', function() {
  console.log('cliked filter vs');

      var originVS  = window.location.origin;
      var urlVS = originVS  + '/wp-admin/admin-ajax.php';
      var offsetVS = 0;
      var currPageVS = 0;

      console.log('clicked filter VS');
      jQuery.ajax({
        type: 'POST',
        url: urlVS,
        dataType: 'html',
        data: {
          offset: offsetVS,
          currPage: currPageVS,
          action: 'filter_vs_function',
          category: selectedCategoryVS,
          year: selectedYearVS,
          month: selectedMonthVS,
          // date,
        },

        success: function (data) {
          offsetVS = offsetVS + 9;
          currPageVS = currPageVS + 1;
          jQuery('[data-ajax-vs]').html('');
          jQuery('[data-ajax-vs]').append(data);
            
        },
        error: function (errorThrown) {
            console.log('error:', errorThrown);
            console.log('error', $(this).data);

        },
      });
})

/*  
** PDF BUTTON SSTYLE
*/
var pdfButton = jQuery('.wps-pgfw-pdf-generate-icon__wrapper-frontend');

if(pdfButton){
  pdfButton.addClass('button');
  pdfButton.addClass('button--secondary');
  const link = jQuery(pdfButton).find('a');
  link.html('PDF speichern');
}





// if(protokolleContainer) {
//   const div = jQuery(protokolleContainer).find('[data-ajax]');

//   //count sibling
//   var countTeaser = div.children().length;

//   if(countTeaser > 9){
//     loadMoreButton.parent().hide();
//   } else {
//     loadMoreButton.parent().show();
//   }
// }

/*  
** LOAD MORE PROTOKOLLE
*/
loadMoreButton.on('click', function() {  

  var originLM  = window.location.origin;
  var urlLM = originLM  + '/wp-admin/admin-ajax.php';

      jQuery.ajax({
        type: 'POST',
        url: urlLM,
        dataType: 'html',
        data: {
          action: 'filter_lm_function',
        },

        success: function (data) {
          jQuery('[data-ajax]').html('');
          jQuery('[data-ajax]').append(data);
          loadMoreButton.hide();
            
        },
        error: function (errorThrown) {
            console.log('error:', errorThrown);
            console.log('error', $(this).data);

        },
      });
})

/*  
** LOAD MORE VORSTANDSSITZUNG
*/
loadMoreButtonVS.on('click', function() {  
  console.log('clicked load more vs');
  var originLMVS  = window.location.origin;
  var urlLMVS = originLMVS  + '/wp-admin/admin-ajax.php';

      jQuery.ajax({
        type: 'POST',
        url: urlLMVS,
        dataType: 'html',
        data: {
          action: 'filter_lm_vs_function',
        },

        success: function (data) {
          jQuery('[data-ajax-vs]').html('');
          jQuery('[data-ajax-vs]').append(data);
          loadMoreButtonVS.hide();
            
        },
        error: function (errorThrown) {
            console.log('error:', errorThrown);
            console.log('error', $(this).data);

        },
      });
})



///Filter mitglieder

const filterButtonMG = jQuery(document).find('[data-filter-button-mitglieder]');


var filterFormRoleMG = jQuery('[data-select-role-mitglieder]');
var filterFormCategoryMG = jQuery('[data-select-category-mitglieder]');

console.log('onchange', filterFormCategoryMG);
console.log('container h3',  jQuery('[data-headline-mitglieder]'));


var selectedRoleMG = 'unset'
var selectedCategoryMG = 'unset';

filterFormRoleMG.on('change', function() {
  selectedRoleMG = jQuery(this).children('option:selected').val()  

})

filterFormCategoryMG.on('change', function() {
  selectedCategoryMG = jQuery(this).children('option:selected').val()



  var headline = jQuery('[data-headline-mitglieder]');

  if(selectedCategoryMG === 'cat-elementar') {
    headline.html('Elementargruppe');
  }

  if(selectedCategoryMG === 'cat-kita') {
    headline.html('Kitagruppe');
  }
  // if(selectedCategoryMG === 'cat-dritte') {
  //   headline.html('dritte');
  // }
})


filterButtonMG.on('click', function() {

      var origin  = window.location.origin;
      var url = origin  + '/wp-admin/admin-ajax.php';
      var offset = 0;
      var currPage = 0;

      console.log('clicked filter mitglieder');
      jQuery.ajax({
        type: 'POST',
        url: url,
        dataType: 'html',
        data: {
          offset: offset,
          currPage: currPage,
          action: 'filter_function_mg',
          category: selectedCategoryMG,
          role: selectedRoleMG,
          // date,
        },

        success: function (data) {
          offset = offset + 9;
          currPage = currPage + 1;
          jQuery('[data-ajax-mitglieder]').html('');
          jQuery('[data-ajax-mitglieder]').append(data);
            
        },
        error: function (errorThrown) {
            console.log('error:', errorThrown);
            console.log('error', $(this).data);

        },
      });
})

