export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');

    $('.hamburger').on('click', function() {
      $(this).toggleClass('is-active');
    });


    // Create a new element
    var newNode = document.createElement('h3');

    newNode.innerHTML = 'Login Intranet';

    // Get the reference node
    var referenceNode = document.querySelector('#login h1');

    if(referenceNode) {
      referenceNode.after(newNode);
    }

    // Insert the new node before the reference node


    var rememberMe = document.querySelector('#login .forgetmenot');

    var passwordReset = document.querySelector('#login #nav');

    if(rememberMe && passwordReset){
      rememberMe.after(passwordReset);
    }

    // Insert the new node before the reference node

  const essenslisteTeaser = document.querySelectorAll('.essensliste-teaser');
  // const showEssensliste = document.querySelectorAll('.show-essensliste');
  // console.log('essenslisteTeaser', essenslisteTeaser);

  if(essenslisteTeaser) {
    essenslisteTeaser.forEach(function(teaser) {



      teaser.addEventListener('click', () => {

        console.log('teaser');
        let modal = teaser.querySelector('.modal');

        if (modal.style.display == 'none') {
          modal.style.display = 'block'
      } else {
          modal.style.display = 'none'
      }

      });


    })

  }

  // if(showEssensliste) {
  //   essenslisteTeaser.forEach(function(teaser) {



  //     teaser.addEventListener('click', () => {

  //       console.log('teaser');
  //       let modal = teaser.querySelector('.modal');

  //       if (modal.style.display == 'none') {
  //         modal.style.display = 'block'
  //     } else {
  //         modal.style.display = 'none'
  //     }

  //     });


  //   })

  // }

  const modalCloses = document.querySelectorAll('.modal-close');
  console.log('close', modalCloses);

  if(modalCloses) {

    modalCloses.forEach(function(close) {


      close.addEventListener('click', () => {

        if(close.closest('.modal').classList.contains('visible')) {
          close.closest('.modal').classList.add('hide');
        }
      });


    })

  }

  const arrowCloses = document.querySelectorAll('.corona .arrow-close');

  if(arrowCloses) {

    arrowCloses.forEach(function(close) {
      let screensize = window.innerWidth;
        
      if(screensize < 768) {
        close.closest('.corona').querySelector('.content').classList.add('hide');

      }

      close.addEventListener('click', () => {
        console.log('clicked');

        let content = close.closest('.corona').querySelector('.content');
        let corona = close.closest('.corona');
        corona.classList.toggle('active');
        content.classList.toggle('hide');

        // if(content) {
        //   if(content.classList.contains('hide')){
        //     content.classList.remove('hide');
            

        //   } else {
        //     content.classList.add('hide');
        //   }
        // }
      });


    })

  }

  const scrollBtn = document.querySelector('#scrolltop');
  scrollBtn.addEventListener('click', () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
});


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
